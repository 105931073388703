import { Button, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import useLoaderDebounce from '../helpers/useLoaderDebounce';
import Spinner from '../Spinner';

export interface PrimaryButtonProps {
    onClick: MouseEventHandler;
    loading?: boolean;
    children: string;
    icon?: JSX.Element;
    disabled?: boolean;
    fullWidth?: boolean;
    submitsForm?: boolean;
    className?: string;
    small?: boolean;
    variation?: 'greyed-out' | 'danger';
}

const useStyles = makeStyles(theme => ({
    withLeftIcon: { paddingLeft: '.7em' },
    iconLeft: { marginLeft: '.5em' },
    iconRight: { marginRight: '.5em' },
    greyedOut: {
        backgroundColor: '#9E9E9E',
        '&:hover': { backgroundColor: '#757575' },
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        '&:hover': { backgroundColor: theme.palette.error.dark },
    },
}));

const PrimaryButton: FunctionComponent<React.PropsWithChildren<PrimaryButtonProps>> = (
    {
        children, loading, onClick, icon, disabled,
        fullWidth, submitsForm, className, small, variation,
    },
) => {
    const classes = useStyles();
    const showSpinner = useLoaderDebounce(loading ?? false);

    const leftIcon = icon && showSpinner
        ? <Spinner size={20} />
        : icon;

    // TODO: Better animation (zoom)
    const rightIcon = showSpinner && !icon
        ? (
            <Fade in>
                <div>
                    <Spinner size={20} />
                </div>
            </Fade>
        )
        : null;

    return (
        <Button
            color="secondary"
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            disabled={disabled || loading}
            onClick={onClick}
            type={submitsForm ? 'submit' : 'button'}
            variant="contained"
            className={clsx({
                [classes.withLeftIcon]: leftIcon,
                [classes.greyedOut]: variation === 'greyed-out',
                [classes.danger]: variation === 'danger',
            }, className)}
            fullWidth={fullWidth}
            size={small ? 'small' : undefined}
        >
            {leftIcon}

            <span
                className={clsx({
                    [classes.iconLeft]: leftIcon,
                    [classes.iconRight]: rightIcon,
                })}
            >
                {children}
            </span>

            {rightIcon}
        </Button>
    );
};

export default PrimaryButton;
